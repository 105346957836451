@import './assets/fonts/AdellePE/index.css';
@import './assets/fonts/ProximaNova/index.css';

body {
  margin: 0;
  font-family: 'ProximaNova', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
}

/* Base font size */
html {
  font-size: 14px;
}
@media only screen and (min-width: 48em) {
  html {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'AdellePE', 'serif';
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  width: 100%;
  background-color: #f2f4fa;
  border-color: #f2f4fa;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 10;
}
